import React from 'react';
import {Badge, Stack} from "react-bootstrap";
import ListGroup from 'react-bootstrap/ListGroup';
import {convertToGermanDate, activity, registrationColor, ManageConferenceLink} from "./statistikTools";
import {Link, useLocation} from "react-router-dom";

const getNextFutureDateObj = (dates) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Finde alle zukünftigen Termine (heute oder später)
    const futureDates = dates
        .map((item, index) => ({ ...item, index, dateObj: new Date(item.date) }))
        .filter(entry => {
            entry.dateObj.setHours(0, 0, 0, 0);
            return entry.dateObj >= today;
        });

    // Wenn keine zukünftigen Termine vorhanden sind, gebe das Objekt an Index 0 zurück
    if (futureDates.length === 0) {
        return dates[0];
    }

    // Sortiere die zukünftigen Termine nach Datum
    futureDates.sort((a, b) => a.dateObj - b.dateObj);

    // Gib den nächstliegenden zukünftigen Termin zurück
    return futureDates[0];
};

const BeforeEventData = (props) => {
    const location = useLocation();
    const sortedEvents = Object.keys(props.eventsData)
        .sort((a, b) => new Date(props.eventsData[a].firstEvent) - new Date(props.eventsData[b].firstEvent))
        .map(key => ({
            key,
            ...props.eventsData[key]
        }));

    return (
        <ListGroup as="ol" numbered>
            {sortedEvents.map((event, index) => (
                <ListGroup.Item eventKey={index} key={event.key}
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                >
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">
                            <Link
                                to={`/training/${event.trainingsData.id}`}
                                state={{ backgroundLocation: location }}
                            >
                                <span style={{hyphens: 'auto'}}>
                                    {`${event.trainingsData.title} [${event.trainingsData.displayNumber}]`}
                                </span>
                            </Link>
                        </div>
                        <div>
                            {convertToGermanDate(getNextFutureDateObj(event.trainingsData.dates).date, true)} von {getNextFutureDateObj(event.trainingsData.dates).timeBegin} bis {getNextFutureDateObj(event.trainingsData.dates).timeEnd}
                        </div>
                        <div>
                            {
                                ((event.trainingsData.art === "Online-Kurs") || (event.trainingsData.art === "Blended-Learning-Kurs")) &&
                                <ManageConferenceLink
                                    link={event.trainingsData.bbbLinkIntern}
                                    code={event.trainingsData.bbbCode}
                                    id={event.trainingsData.id}
                                    displayNumber={event.trainingsData.displayNumber}
                                    title={event.trainingsData.title}
                                >
                                </ManageConferenceLink>
                            }
                        </div>
                    </div>
                    <div>
                        <Stack direction="horizontal" gap={1}>
                            {
                                event.trainingsData.inProgress  &&
                                <Badge
                                    bg="warning"
                                    text="dark"
                                    title={'Fortbildung ist noch in der Bearbeitung, daher nicht online buchbar!'}
                                    aria-label={'Fortbildung ist noch in der Bearbeitung, daher nicht online buchbar!'}
                                >
                                    !
                                </Badge>
                            }
                            <Badge
                                bg={registrationColor(event.trainingsData.registrationCount, event.trainingsData.max).bg}
                                style={{ marginRight: '0.5em' }}
                                title={`${event.trainingsData.registrationCount} von maximal ${event.trainingsData.max} Teilnehmenden angemeldet`}
                                aria-label={`${event.trainingsData.registrationCount} von maximal ${event.trainingsData.max} Teilnehmenden angemeldet`}
                            >
                                {activity(event.trainingsData.registrationCount, event.trainingsData.max)}
                            </Badge>
                        </Stack>
                    </div>
                </ListGroup.Item>
            ))}
        </ListGroup>
    );
}

export default BeforeEventData;
